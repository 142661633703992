export default {
  'slot1': "1789514140",
  'slot2': "4224105799",
  'slot3': "2156662889",
  "cid":97,
  'firebaseConfig': {
  apiKey: "AIzaSyCCI3z3r_4hBBqT7i8vq807sVlfY5uS1uE",
  authDomain: "sports1-network.firebaseapp.com",
  projectId: "sports1-network",
  storageBucket: "sports1-network.appspot.com",
  messagingSenderId: "331970102462",
  appId: "1:331970102462:web:c818927088393f1aaf0aef",
  measurementId: "G-0P63CTEYDP"
  }
}